
import { IonSlides, IonSlide } from "@ionic/vue";

export default {
  name: "Carousel",
  components: { IonSlides, IonSlide },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const slideOpts = {
      initialSlide: 1,
      speed: 400,
    };
    return { slideOpts };
  },
};
