
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/vue";
import { reactive, toRefs, watch } from "vue";
import citys from "@/utils/city";

export default {
  name: "optionMenu",
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: 0,
      required: false,
    },
  },
  emits: {
    "update:modelValue": null,
  },
  setup(props: any, { emit }: any) {
    const data = reactive({
      province: null,
      city: null,
      district: null,
    });

    const findChild = (arr, siteCode) => {
      const { children } = arr.find(
        (item) => JSON.stringify(item) === siteCode
      ) || {
        children: [],
      };
      return children;
    };

    watch(
      () => data.province,
      () => {
        data.city = null;
        data.district = null;
        emit("update:modelValue", {
            province: JSON.parse(data.province),
            city: JSON.parse(data.city),
            district: JSON.parse(data.district),
          });
      }
    );
    watch(
      () => data.city,
      () => {
        data.district = null;
        emit("update:modelValue", {
            province: JSON.parse(data.province),
            city: JSON.parse(data.city),
            district: JSON.parse(data.district),
          });
      }
    );

    watch(
      () => data.district,
      () => {
        if (data.province && data.city && data.district) {
          emit("update:modelValue", {
            province: JSON.parse(data.province),
            city: JSON.parse(data.city),
            district: JSON.parse(data.district),
          });
        }
      }
    );

    return { ...toRefs(data), citys, findChild };
  },
};
