import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c9c4674"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!

  return ($props.list.length > 0)
    ? (_openBlock(), _createBlock(_component_ion_slides, {
        key: 0,
        pager: "true",
        options: $setup.slideOpts
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item) => {
            return (_openBlock(), _createBlock(_component_ion_slide, {
              key: item.adsort
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: item.adcode,
                  class: "slide-image"
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["options"]))
    : _createCommentVNode("", true)
}