
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonModal,
  onIonViewDidEnter,
  onIonViewWillEnter,
  IonTextarea,
} from "@ionic/vue";
import { chevronForward, eye, chatboxEllipses } from "ionicons/icons";
import { onMounted, watch, reactive, computed, toRefs, ref } from "vue";
import {
  getHomeDataInfo,
  getSlides,
  feedbackcommit,
  getZizhiForHomeList,
  getQualifications,
} from "@/service/home";
import { getDicSimpleList } from "@/service/member";
import Carousel from "@/components/carousel.vue";
import OptionMenu from "@/components/optionMenu.vue";
import SelectRegion from "@/components/selectRegion.vue";
import SelectQualifications from "@/components/selectQualifications.vue";
import { openTips, openModal, openImages } from "@/utils/alert";
import { skipRouter } from "@/router";
//存入全局变量 1、首先导入这个方法
import { useStore } from "vuex";
import { getDate } from "@/utils/moment";
import { userinfo, checklogin } from "@/utils/usermodule";

export default {
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    Carousel,
    OptionMenu,
    IonButton,
    IonTextarea,
    SelectRegion,
    SelectQualifications,
  },
  setup() {
    // 2、调用这个方法
    const loginuserstore = useStore();
    // 3、如果要获取全局状态就需要声明变量
    const loginuserInfo = computed(
      () => loginuserstore.getters["user/userInfo"]
    );

    console.log(loginuserInfo.value); // 打印一下 确定没改变之前的值
    const homezizhitypeinfo: any = ref([]);
    const homedatainfo: any = ref([]);
    const homedataslides: any = ref([]);
    const ZizhiImgIdList: any = ref([]);
    const isOpenQualifications = ref(false);

    const hehuorendata = {
      menus: [
        {
          label: "项目合伙",
          value: 1,
        },
        {
          label: "区域合伙",
          value: 2,
        },
        {
          label: "其他合伙",
          value: 3,
        },
      ],
      checkedMenus: ref(1),
    };
    const formdata: any = reactive({
      checkArea: null,
      UserName: "",
      FeedBackType: "1",
      LastCustomer: "",
      ZizhiList: "",
      ZoneArea: "",
      Province: "",
      City: "",
      District: "",
      Remark: "",
      CooperateMode: "1",
      Mobile: "",
    });

    const menuOptions = ref([]);

    const methods = {
      async getSwipers() {
        const dataslides: any = await getSlides({
          positionid: "1",
        });

        homedataslides.value = dataslides;
        console.log(homedataslides);
      },
      async getHomeData() {
        const homedata: any = await getHomeDataInfo({});
        console.log(homedata);
        homedatainfo.value = homedata;
      },
      skip(id: any) {
        skipRouter("/tabs/information/detail", {
          id,
        });
      },
      async getZizhiTypeTeamSettingDetail() {
        const result: any = await getDicSimpleList({
          enCode: "ZizhiType",
        });
        result.forEach(async ({ itemname, itemvalue }: any) => {
          const list = await getQualifications({ typeid: itemvalue });
          menuOptions.value.push({
            label: itemname,
            value: itemvalue,
            list,
          });
        });
      },
    };

    const submitFeedBack = async () => {
      //检测是否登录
      if (!checklogin()) {
        return;
      }

      if (hehuorendata.checkedMenus.value === 1) {
        if (formdata.LastCustomer === "") {
          openTips("承建单位不能为空!");
          return;
        }
      }
      if (hehuorendata.checkedMenus.value === 2) {
        if (formdata.checkArea === null) {
          openTips("合作区域省市区不能为空!");
          return;
        } else {
          formdata.ZoneArea = formdata.checkArea.province.name;

          if (formdata.checkArea.city != null) {
            formdata.ZoneArea =
              formdata.checkArea.province.name +
              "-" +
              formdata.checkArea.city.name;
          }
          if (formdata.checkArea.district != null) {
            formdata.ZoneArea =
              formdata.checkArea.province.name +
              "-" +
              formdata.checkArea.city.name +
              "-" +
              formdata.checkArea.district.name;
          }
        }
      }

      if (formdata.UserName === "") {
        openTips("申请人姓名不能为空!");
        return;
      }
      if (formdata.Mobile === "") {
        openTips("联系电话不能为空!");
        return;
      }
      if (formdata.Remark === "") {
        openTips("业务内容不能为空!");
        return;
      }
      formdata.CooperateMode = hehuorendata.checkedMenus.value;

      if (0 < ZizhiImgIdList.value.length) {
        for (let i = 0; i < ZizhiImgIdList.value.length; i++) {
          formdata.ZizhiList =
            ZizhiImgIdList.value[i].zizhiName + "," + formdata.ZizhiList;
        }
      }
      console.log(formdata);
      const result = await feedbackcommit(formdata);
      if (result) {
        openModal("恭喜您，提交成功!");
      }
    };

    const getZizhiForHomeListPage = async () => {
      const result = await getZizhiForHomeList({});
      console.log(result);
      homezizhitypeinfo.value = result;
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      methods.getSwipers();
      methods.getHomeData();
      methods.getZizhiTypeTeamSettingDetail();
      getZizhiForHomeListPage();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return {
      isOpenQualifications,
      menuOptions,
      hehuorendata,
      homedataslides,
      homedatainfo,
      ZizhiImgIdList,
      methods,
      skipRouter,
      chevronForward,
      eye,
      formdata,
      submitFeedBack,
      chatboxEllipses,
      openImages,
      getDate,
    };
  },
};
